interface IBankAccountDetails {
    iban: string;
    bic: string;
    beneficiary: string;
}

interface IStripeAccountDetails {
    publicKey: string;
    company: string;
}

enum PaymentMethod {
    card = 1,
    bankAccount = 2,
}

export {
    PaymentMethod,
};

export type {
    // eslint-disable-next-line import/prefer-default-export
    IBankAccountDetails,
    IStripeAccountDetails,
};
