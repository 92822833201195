/* eslint-disable import/prefer-default-export */

const getQueryParams = (url: string) => {
    const rawParams = new URLSearchParams(new URL(url).search);

    const params = [ ...rawParams.entries() ]
        .reduce(
            (p, [ key, value ]) => ({
                ...p,
                [key]: value,
            }),
            {},
        );
    return params;
};

export {
    getQueryParams,
};
