import {
    useEffect,
    useState,
} from 'react';

const useCurrentLocation = () => {
    const [ currentURL, setCurrentURL ] = useState(window.location.href);

    useEffect(() => {
        const handleLocationChange = () => {
            setCurrentURL(window.location.href);
        };

        window.addEventListener('popstate', handleLocationChange);

        return () => {
            window.removeEventListener('popstate', handleLocationChange);
        };
    }, []);

    return currentURL;
};

export {
// eslint-disable-next-line import/prefer-default-export
    useCurrentLocation,
};
