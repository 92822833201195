import { useEffect, useState } from 'react';

import { usePayment } from '../../hooks/use-payment';
import { useServices } from '../../hooks/use-services';
import { createBoricaModelUrl } from '../../utils/urls';
import Button, { ButtonType } from '../guidelines/buttons/Button';
import Heading, { HeadingType } from '../guidelines/headings/Heading';

import styles from './CardPayment.module.scss';

const CardPayment = () => {
    const { httpService } = useServices();

    const { state: { id } } = usePayment();

    const [ boricaFormData, setBoricaFormData ] = useState<any>(null);

    useEffect(() => {
        (async () => {
            const { data } = await httpService.post(`${createBoricaModelUrl}${id ?? -1}`);
            setBoricaFormData({
                TRTYPE: data.transactionType,
                AMOUNT: data.amount,
                CURRENCY: data.currency,
                ORDER: data.order,
                DESC: data.description,
                MERCH_URL: data.merchantUrl,
                MERCHANT: data.merchantId,
                TERMINAL: data.terminalId,
                MERCH_NAME: data.merchantName,
                COUNTRY: data.country,
                MERCH_GMT: data.merchantGmt,
                TIMESTAMP: data.timestamp,
                NONCE: data.nonce,
                P_SIGN: data.signature,
                'AD.CUST_BOR_ORDER_ID': data.custBorOrderId,
                ADDENDUM: data.addendum,
                RFU: 'test',
            });
        })();
    }, [ httpService, id ]);

    return (
        <div className={styles.wrapper}>
            <Heading type={HeadingType.small}>
                Поръчката е приета
            </Heading>
            <p className={styles.paymentInfoWrapper}>
                <span>
                    Номер на поръчката
                </span>
                <Heading type={HeadingType.secondary}>{id}</Heading>
            </p>
            <div className={styles.boricaWrapper}>
                <form action="https://3dsgate-dev.borica.bg/cgi-bin/cgi_link" method="POST">
                    {boricaFormData && Object.keys(boricaFormData)
                        .map((name) => <input type="hidden" key={name} name={name} value={boricaFormData[name]} />)}
                    <Button
                      type={ButtonType.submit}
                      text="Продължи към плащане"
                      onClick={() => { }}
                    />
                </form>
            </div>
        </div>
    );
};

export default CardPayment;
