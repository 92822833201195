const TermsError = 'Общите условия трябва да бъдат приети.';
const EmptyFieldError = 'Попълнете задължителните полета.';
const InvalidEmailError = 'Въведете валиден email.';
const InvalidPersonalIdentificationNumberError = 'Въведете валидно ЕГН.';
const InvalidAmountError = 'Сумата е невалидна.';
const InvalidPhoneNumber = 'Веведете валиден телефонен номер.';
const EmailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const PersonalIdentificationNumberRegex =
    /^([0-9][0-9])(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])(\d{4})$/;
const PhoneNumberRegex = /^[\\+]?[(]?[0-9]{3}[)]?[-\s\\.]?[0-9]{3}[-\s\\.]?[0-9]{4,6}$/;

export {
    InvalidPersonalIdentificationNumberError,
    PersonalIdentificationNumberRegex,
    InvalidAmountError,
    InvalidEmailError,
    EmptyFieldError,
    PhoneNumberRegex,
    InvalidPhoneNumber,
    TermsError,
    EmailRegex,
};
