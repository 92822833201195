import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import isNil from 'lodash/isNil';

import addressLogo from '../assets/logos/address-logo.png';
import imotekaLogo from '../assets/logos/imoteka-logo.png';
import addressMobileLogo from '../assets/logos/mobile/address-mobile-logo.svg';
import imotekaMobileLogo from '../assets/logos/mobile/imoteka-mobile-logo.svg';
import newEstatesMobileLogo from '../assets/logos/mobile/new-estates-mobile-logo.svg';
import uniqueMobileLogo from '../assets/logos/mobile/unique-mobile-logo.svg';
import newEstatesLogo from '../assets/logos/new-estates-logo.png';
import uniqueLogo from '../assets/logos/unique-logo.png';
import { Company } from '../common/companies';
import { IHaveChildrenProps } from '../components/common/Props';

import { useCurrentLocation } from './use-current-location';

interface ICompaniesContext {
    state: {
        company: Company;
        logo: string;
        mobileLogo: string;
    };
}

const CompanyLogoMap = {
    [Company.Address]: { logo: addressLogo, mobileLogo: addressMobileLogo },
    [Company.Imoteka]: { logo: imotekaLogo, mobileLogo: imotekaMobileLogo },
    [Company.NewEstates]: { logo: newEstatesLogo, mobileLogo: newEstatesMobileLogo },
    [Company.UniqueEstates]: { logo: uniqueLogo, mobileLogo: uniqueMobileLogo },
};
interface ICompaniesProviderProps extends IHaveChildrenProps {
}

const CompaniesContext = createContext<ICompaniesContext>({ state: {} } as ICompaniesContext);

const CompaniesProvider = ({ children }: ICompaniesProviderProps) => {
    const location = useCurrentLocation();
    const [ logo, setLogo ] = useState('');
    const [ mobileLogo, setMobileLogo ] = useState('');

    const company = useMemo(
        () => {
            const locationLower = location.toLowerCase();

            const currentCompany = Object.entries(Company)
                .find(([ , value ]) => locationLower.includes(`/${value}`));

            return isNil(currentCompany)
                ? Company.Address
                : currentCompany[1];
        },
        [ location ],
    );

    useEffect(() => {
        /* eslint prefer-destructuring: "error" */
        const { logo: companyLogo, mobileLogo: companyMobileLogo } = CompanyLogoMap[company];
        setLogo(companyLogo);
        setMobileLogo(companyMobileLogo);
    }, [ company ]);

    const value = useMemo(
        () => ({
            state:
            {
                company,
                logo,
                mobileLogo,
            },
        }),
        [ company, logo, mobileLogo ],
    );

    return (
        <CompaniesContext.Provider value={value}>
            {children}
        </CompaniesContext.Provider>
    );
};

const useCompanies = () => useContext(CompaniesContext);

export default CompaniesProvider;

export {
    useCompanies,
    Company,
};
