enum PaymentType {
    purchaseDeposit = 0,
    purchaseCommission = 1,
    salesCommission = 2,
    rentDeposit = 3,
    rentCommission = 4,
}

enum PaymentTypesNames {
    purchaseDeposit = 'Депозит за покупка',
    purchaseCommission = 'Комисионна за покупка',
    salesCommission = 'Комисионна за продажба',
    rentDeposit = 'Депозит за наем',
    rentCommission = 'Комисионна за наем'
}

export {
    PaymentType,
    PaymentTypesNames,
};
