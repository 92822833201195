import { useMemo } from 'react';

import { useCompanies } from '../../hooks/use-companies';
import concatClassNames from '../../utils/class-names';

import styles from './CompletedPayment.module.scss';

const CompletePayment = () => {
    const { state: { company } } = useCompanies();
    const wrapperClassName = useMemo(
        () => concatClassNames(styles.wrapper, company),
        [ company ],
    );

    const containerClassName = useMemo(
        () => concatClassNames(styles.container, styles[company]),
        [ company ],
    );

    return (
        <div className={wrapperClassName}>
            <div className={containerClassName}>
                <h1 className={styles.heading}>
                    Плащането е успешно!
                </h1>
            </div>
        </div>
    );
};

export default CompletePayment;
