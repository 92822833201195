import axios, { ResponseType } from 'axios';
import isFunction from 'lodash/isFunction';

import { IDictionary, UrlType } from '../common/common-types';

const getUrl = <P>(url: UrlType<P>, params?: IDictionary<P> | null) => (
    isFunction(url)
        ? url(params)
        : url
);

interface IHttpCallParams {
    url: string;
    method: string;
    headers?: IDictionary<string>;
    responseType: string;
    body?: any;
}

const makeHttpCall = async ({
    url,
    method,
    headers = {},
    body = null,
    responseType,
}: IHttpCallParams) => {
    const makeCall = () => axios({
        method,
        url,
        data: body,
        responseType: responseType as ResponseType,
        withCredentials: true,
        headers,
    });

    return makeCall();
};

export {
    getUrl,
    makeHttpCall,
};

export type {
    IHttpCallParams,
};
