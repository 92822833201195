import { useMemo } from 'react';

import mastercardLogo from '../../../assets/logos/mastercard-logo.png';
import stripeLogo from '../../../assets/logos/stripe-logo.png';
import visaLogo from '../../../assets/logos/visa-logo.png';
import { useCompanies } from '../../../hooks/use-companies';
import concatClassNames from '../../../utils/class-names';
import { IHaveOptionalClassName } from '../../common/Props';

import styles from './Banner.module.scss';
import TermsOfUseButton from '../../footer-links/TermsOfUseButton';
import PrivacyPolicyButton from '../../footer-links/PrivacyPolictyButton';
import ZzlpspoinButton from '../../footer-links/ZzlpspoinButton';

interface IBannerProps extends IHaveOptionalClassName {
    imgSrc?: string;
    mobileImgSrc?: string;
    altText?: string;
}

const Banner = ({ imgSrc = '', mobileImgSrc = '', altText = '', className = '' }: IBannerProps) => {
    const { state: { company } } = useCompanies();
    const containerClassname = useMemo(() => concatClassNames(className, styles.banner, company), [ company, className ]);

    return (
        <div className={containerClassname}>
            <div className={styles.bannerLogoContainer}>
                <img className={styles.logo} src={imgSrc} alt={altText} />
                <img className={styles.logoMobile} src={mobileImgSrc} alt={altText} />
            </div>
            <div className={styles.bannerFooterContainer}>
                <div>
                    <img src={stripeLogo} alt="stripe" />
                    <img src={mastercardLogo} alt="mastercard" />
                    <img src={visaLogo} alt="visa" />
                </div>
                <div className={styles.bannerLinksContainer}>
                    <div className={styles.bannerLinks}>
                        <TermsOfUseButton />
                        <PrivacyPolicyButton />
                        <ZzlpspoinButton />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Banner;
