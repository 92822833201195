import { useCallback, useMemo } from 'react';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

import leftArrow from '../../assets/button/left-arrow.svg';
import { IDictionary } from '../../common/common-types';
import { DEFAULT_DATE_FORMAT } from '../../common/dates';
import { PaymentType, PaymentTypesNames } from '../../common/payments';
import { useBankAccount } from '../../hooks/use-bank-account';
import { useCompanies } from '../../hooks/use-companies';
import { useDrafts } from '../../hooks/use-drafts';
import { useNavigation } from '../../hooks/use-navigation';
import { usePayment } from '../../hooks/use-payment';
import { copyTextToClipboard } from '../../utils/clipboard-utils';
import Button, { ButtonMove, ButtonSize, ButtonType } from '../guidelines/buttons/Button';
import Text, { TextType } from '../guidelines/text/Text';

import styles from './AccountPayment.module.scss';

const AccountPayment = () => {
    const { state: { company } } = useCompanies();
    const { state: { draft } } = useDrafts();

    const { actions: { updateDraftPaymentDetails } } = usePayment();

    const { goBackward } = useNavigation();
    const { state: { bankAccountDetails } } = useBankAccount();

    const {
        iban,
        beneficiary,
        bic,
    } = useMemo(
        () => bankAccountDetails,
        [ bankAccountDetails ],
    );

    const { paymentType, offerCode, contractDate } = useMemo(
        () => draft,
        [ draft ],
    );

    const handleCopyToClipboard = useCallback(
        async (value: string) => {
            await copyTextToClipboard(value);
        },
        [ ],
    );

    const renderCopyIcon = useCallback(
        (value: string) => (
            <Button
              type={ButtonType.plain}
              onClick={() => handleCopyToClipboard(value)}
              company={company}
            >
                <FontAwesomeIcon icon={faCopy} />
            </Button>
        ),
        [ company, handleCopyToClipboard ],
    );

    const basisForPayment = useMemo(
        () => {
            const { [paymentType]: key } = PaymentType;
            // eslint-disable-next-line prefer-destructuring
            const paymentTypeName = (PaymentTypesNames as IDictionary<string>)[key];

            const dateAsString = contractDate
                ? moment(contractDate, DEFAULT_DATE_FORMAT).format(DEFAULT_DATE_FORMAT)
                : '';

            if (paymentType.toString() === PaymentType.purchaseDeposit.toString() ||
                paymentType.toString() === PaymentType.rentDeposit.toString()) {
                return `По договор за гаранция от дата ${dateAsString} код на оферта ${offerCode}.`;
            }

            return `${paymentTypeName} по договор за обслужване от дата ${dateAsString} код на оферта ${offerCode}.`;
        },
        [ offerCode, paymentType, contractDate ],
    );

    const handleSubmit = useCallback(
        async () => {
            await updateDraftPaymentDetails();
            window.location.href = 'payment-successful';
        },
        [ updateDraftPaymentDetails ],
    );

    return (
        <>
            <div>
                <div className={styles.container}>
                    <div className={styles.internalContainer}>
                        <Text
                          type={TextType.Muted}
                          company={company}
                        >
                            IBAN
                        </Text>
                        <Text
                          type={TextType.Bold}
                          company={company}
                        >
                            {iban}
                        </Text>
                    </div>
                    {renderCopyIcon(iban)}
                </div>
                <div className={styles.container}>
                    <div className={styles.internalContainer}>
                        <Text
                          type={TextType.Muted}
                          company={company}
                        >
                            BIC
                        </Text>
                        <Text
                          type={TextType.Bold}
                          company={company}
                        >
                            {bic}
                        </Text>
                    </div>
                    {renderCopyIcon(bic)}
                </div>
                <div className={styles.container}>
                    <div className={styles.internalContainer}>
                        <Text
                          type={TextType.Muted}
                          company={company}
                        >
                            Бенефициент
                        </Text>
                        <Text
                          type={TextType.Bold}
                          company={company}
                        >
                            {beneficiary}
                        </Text>
                    </div>
                    {renderCopyIcon(beneficiary)}
                </div>
                <div className={styles.container}>
                    <div className={styles.internalContainer}>
                        <Text
                          type={TextType.Muted}
                          company={company}
                        >
                            Основание на плащане
                        </Text>
                        <Text
                          type={TextType.Bold}
                          company={company}
                        >
                            {basisForPayment}
                        </Text>
                    </div>
                    {renderCopyIcon(beneficiary)}
                </div>
            </div>
            <div className={styles.buttonsContainer}>
                <Button
                  imgSrc={leftArrow}
                  size={ButtonSize.medium}
                  type={ButtonType.secondary}
                  move={ButtonMove.left}
                  company={company}
                  text="Назад"
                  onClick={goBackward}
                />
                <Button
                  size={ButtonSize.medium}
                  type={ButtonType.submit}
                  move={ButtonMove.right}
                  company={company}
                  text="Завърши"
                  onClick={handleSubmit}
                />
            </div>
        </>
    );
};

export default AccountPayment;
