import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import InitProviders, { ProviderType } from './components/common/InitProviders';
import ContentWrapper from './components/layout/content-wrapper/ContentWrapper';
import BankAccountProvider from './hooks/use-bank-account';
import CompaniesProvider from './hooks/use-companies';
import DefaultParamsProvider from './hooks/use-default-params';
import DraftsProvider from './hooks/use-drafts';
import NavigationProvider from './hooks/use-navigation';
import PaymentProvider from './hooks/use-payment';
import ServicesProvider from './hooks/use-services';
import ValidationTriggerProvider from './hooks/use-validation-trigger';

import './App.css';

const App = () => {
    const providers = [
        NavigationProvider,
        PaymentProvider,
        BankAccountProvider,
        DraftsProvider,
        DefaultParamsProvider,
        ServicesProvider,
        CompaniesProvider,
        ValidationTriggerProvider,
    ] as ProviderType[];

    const router = createBrowserRouter([
        {
            path: '/',
            element: (
                <ContentWrapper />
            ),
            children: [
                {
                    path: '/:company',
                    element: (
                        <ContentWrapper />
                    ),
                },
            ],
        },
        {
            path: '/:company/payment-successful',
            element: (
                <ContentWrapper successfulPayment />
            ),
        },
        {
            path: '/payment-successful',
            element: (
                <ContentWrapper successfulPayment />
            ),
        },
    ]);

    return (
        <InitProviders providers={providers}>
            <RouterProvider router={router} />
        </InitProviders>
    );
};

export default App;
