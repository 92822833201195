import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import isNil from 'lodash/isNil';

import { useCompanies } from '../../hooks/use-companies';
import { useDrafts } from '../../hooks/use-drafts';
import { useServices } from '../../hooks/use-services';
import Field from '../field/Field';
import FieldsContainer from '../guidelines/fields-container/FieldsContainer';
import FormControl, { FormControlType, FormSize, IFormControlOnChangeValueType } from '../guidelines/forms/FormControl';
import Heading, { HeadingType } from '../guidelines/headings/Heading';
import Label, { LabelType } from '../guidelines/labels/Label';
import RequiredSign from '../required-sign/RequiredSign';

import styles from './PersonalDetails.module.scss';

const names = 'names';
const namesLabel = 'Три имена';
const personalNumber = 'personalNumber';
const personalNumberLabel = 'ЕГН';
const phone = 'phone';
const phoneLabel = 'Телефон';
const email = 'Email';
const address = 'address';
const addressLabel = 'Адрес по лична карта';
const firmName = 'firmName';
const firmNameLabel = 'Име на фирмата';
const mol = 'mol';
const molLabel = 'МОЛ';
const bulstat = 'bulstat';
const bulstatLabel = 'ЕИК / БУЛСТАТ';
const firmAddress = 'firmAddress';
const firmAddressLabel = 'Адрес';
const legalEntity = 'legalEntity';

const PersonalDetails: React.FunctionComponent = () => {
    const { state: { company } } = useCompanies();

    const { draftValidationService } = useServices();

    const {
        state: { draft },
        actions: {
            setName,
            setPersonalIdentificationNumber,
            setEmail,
            setPhone,
            setCustomerAddress,
            setIsLegalEntity,
            setNameOfCompany,
            setFinanciallyLiablePerson,
            setBulstat,
            setAddress,
        },
    } = useDrafts();

    const [ isVisible, setIsVisible ] = useState(draft.isLegalEntity);

    const handleOnChangeNames = useCallback((value?: IFormControlOnChangeValueType) => {
        setName(isNil(value)
            ? ''
            : value.toString());
    }, [ setName ]);

    const handleOnChangePersonalNumber = useCallback((value?: IFormControlOnChangeValueType) => {
        setPersonalIdentificationNumber(isNil(value)
            ? ''
            : value.toString());
    }, [ setPersonalIdentificationNumber ]);

    const handleOnChangeEmail = useCallback((value?: IFormControlOnChangeValueType) => {
        setEmail(isNil(value)
            ? ''
            : value.toString());
    }, [ setEmail ]);

    const handleOnChangePhone = useCallback((value?: IFormControlOnChangeValueType) => {
        setPhone(isNil(value)
            ? ''
            : value.toString());
    }, [ setPhone ]);

    const handleOnChangeAddress = useCallback((value?: IFormControlOnChangeValueType) => {
        setCustomerAddress(isNil(value)
            ? ''
            : value.toString());
    }, [ setCustomerAddress ]);

    const handleOnChangeFirmName = useCallback((value?: IFormControlOnChangeValueType) => {
        setNameOfCompany(isNil(value)
            ? ''
            : value.toString());
    }, [ setNameOfCompany ]);

    const handleOnChangeMol = useCallback((value?: IFormControlOnChangeValueType) => {
        setFinanciallyLiablePerson(isNil(value)
            ? ''
            : value.toString());
    }, [ setFinanciallyLiablePerson ]);

    const handleOnChangeBulstat = useCallback((value?: IFormControlOnChangeValueType) => {
        setBulstat(isNil(value)
            ? ''
            : value.toString());
    }, [ setBulstat ]);

    const handleOnChangeFirmAddress = useCallback((value?: IFormControlOnChangeValueType) => {
        setAddress(isNil(value)
            ? ''
            : value.toString());
    }, [ setAddress ]);

    const handleOnChangeLegalEntity = useCallback(
        (value: boolean) => {
            setIsLegalEntity(value);
        },
        [ setIsLegalEntity ],
    );

    useEffect(() => {
        if (draft.isLegalEntity) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    }, [ draft.isLegalEntity ]);

    return (
        <div className={styles.wrapper}>
            <Heading type={HeadingType.primary} company={company}>2. ДАННИ ЗА КЛИЕНТА</Heading>
            <FieldsContainer>
                <Field>
                    <Label type={LabelType.realto}>
                        {namesLabel}
                        <RequiredSign />
                    </Label>
                    <FormControl
                      id={names}
                      name={names}
                      labelText="Въведете три имена"
                      value={draft.name}
                      type={FormControlType.input}
                      onChange={handleOnChangeNames}
                      validators={[ (value) => draftValidationService.hasValidName(value) ]}
                    />
                </Field>
                <Field>
                    <Label type={LabelType.realto}>
                        {personalNumberLabel}
                        <RequiredSign />
                    </Label>
                    <FormControl
                      id={personalNumber}
                      name={personalNumber}
                      labelText="Въведете ЕГН"
                      value={draft.personalIdentificationNumber}
                      type={FormControlType.input}
                      onChange={handleOnChangePersonalNumber}
                      validators={[ (value) => draftValidationService.hasValidPersonalIdentificationNumber(value) ]}
                    />
                </Field>
                <Field>
                    <Label type={LabelType.realto}>
                        {phoneLabel}
                        <RequiredSign />
                    </Label>
                    <FormControl
                      id={phone}
                      name={phone}
                      labelText="+3598XXXXXX"
                      value={draft.phone}
                      type={FormControlType.input}
                      onChange={handleOnChangePhone}
                      validators={[ (value) => draftValidationService.hasValidPhone(value) ]}
                    />
                </Field>
                <Field>
                    <Label type={LabelType.realto}>
                        {email}
                        <RequiredSign />
                    </Label>
                    <FormControl
                      id={email.toLowerCase()}
                      name={email.toLowerCase()}
                      labelText="Въведенете e-mail"
                      value={draft.email}
                      type={FormControlType.input}
                      onChange={handleOnChangeEmail}
                      validators={[ (value) => draftValidationService.hasValidEmail(value) ]}
                    />
                </Field>
                <Field>
                    <Label type={LabelType.realto}>
                        {addressLabel}
                        <RequiredSign />
                    </Label>
                    <FormControl
                      id={address}
                      name={address}
                      labelText="Въведете точен адрес"
                      value={draft.customerAddress}
                      size={FormSize.half}
                      type={FormControlType.input}
                      onChange={handleOnChangeAddress}
                      validators={[ (value) => draftValidationService.hasValidCustomerAddress(value) ]}
                    />
                </Field>
            </FieldsContainer>
            <Heading
              className={isVisible
                  ? ''
                  : styles.hidden}
              type={HeadingType.secondary}
            >
                Данни за фактура
            </Heading>
            <FieldsContainer>
                <Field className={styles.legalEntityField}>
                    <Label type={LabelType.realto}>
                        Физическо лице
                    </Label>
                    <FormControl
                      name={legalEntity}
                      shouldDisableLabel={false}
                      type={FormControlType.radio}
                      checked={!draft.isLegalEntity}
                      company={company}
                      onChange={() => handleOnChangeLegalEntity(false)}
                      className={styles.checkbox}
                    />
                </Field>
                <Field className={styles.legalEntityField}>
                    <Label type={LabelType.realto}>
                        Юридическо лице
                    </Label>
                    <FormControl
                      name={legalEntity}
                      shouldDisableLabel={false}
                      type={FormControlType.radio}
                      checked={draft.isLegalEntity}
                      company={company}
                      onChange={() => handleOnChangeLegalEntity(true)}
                      className={styles.checkbox}
                    />
                </Field>
                <div className={isVisible
                    ? ''
                    : styles.hidden}
                >
                    <Field>
                        <Label type={LabelType.realto}>
                            {firmNameLabel}
                            <RequiredSign />
                        </Label>
                        <FormControl
                          id={firmName}
                          name={firmName}
                          labelText="Въведете име на фирмата"
                          value={draft.nameOfCompany}
                          type={FormControlType.input}
                          onChange={handleOnChangeFirmName}
                          validators={[ (value) => draftValidationService.hasValidNameOfCompany(value) ]}
                        />
                    </Field>
                    <Field>
                        <Label type={LabelType.realto}>
                            {molLabel}
                            <RequiredSign />
                        </Label>
                        <FormControl
                          id={mol}
                          name={mol}
                          labelText="Въведете МОЛ"
                          value={draft.financiallyLiablePerson}
                          type={FormControlType.input}
                          onChange={handleOnChangeMol}
                          validators={[ (value) => draftValidationService.hasValidFinanciallyLiablePerson(value) ]}
                        />
                    </Field>
                    <Field>
                        <Label type={LabelType.realto}>
                            {bulstatLabel}
                            <RequiredSign />
                        </Label>
                        <FormControl
                          id={bulstat}
                          name={bulstat}
                          labelText="Въведете ЕИК / БУЛСТАТ"
                          value={draft.bulstat}
                          type={FormControlType.input}
                          onChange={handleOnChangeBulstat}
                          validators={[ (value) => draftValidationService.hasValidBulstat(value) ]}
                        />
                    </Field>
                    <Field>
                        <Label type={LabelType.realto}>
                            {firmAddressLabel}
                            <RequiredSign />
                        </Label>
                        <FormControl
                          id={firmAddress}
                          name={firmAddress}
                          labelText="Въведете адрес"
                          value={draft.address}
                          type={FormControlType.input}
                          onChange={handleOnChangeFirmAddress}
                          validators={[ (value) => draftValidationService.hasValidAddress(value) ]}
                        />
                    </Field>
                </div>
            </FieldsContainer>
        </div>
    );
};

export default PersonalDetails;
