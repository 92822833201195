import { ChangeEvent, FormEvent, useCallback, useEffect, useMemo, useState } from 'react';
import isNil from 'lodash/isNil';
import moment from 'moment';

import { Company } from '../../../hooks/use-companies';
import { useValidationTrigger } from '../../../hooks/use-validation-trigger';
import concatClassNames from '../../../utils/class-names';
import generateId from '../../../utils/id-generator';
import { ClassNameType, IHaveChildrenProps, IHaveOptionalClassName } from '../../common/Props';

import styles from './FormControl.module.scss';

enum FormControlType {
    radio = 'radio',
    checkbox = 'checkbox',
    text = 'text',
    textarea = 'textarea',
    input = 'input',
    password = 'password',
    file = 'file',
    number = 'number',
    date = 'date',
}

enum FormSize {
    default = 1,
    half = 2,
}

const defaultDateFormat = 'YYYY.MM.DD';

type IFormControlOnChangeValueType = string | number | boolean | Blob;

type TextAreaOrInputElement = HTMLTextAreaElement | HTMLInputElement;

type ValidatorFuncType = (value: any) => boolean | Promise<boolean>;

interface IFormControlProps extends IHaveOptionalClassName {
    name: string;
    value?: string;
    containerClassName?: string;
    labelText?: string;
    labelClassName?: ClassNameType;
    type?: FormControlType;
    onChange?: ((value?: IFormControlOnChangeValueType) => void) | null;
    onInput?: ((value?: string) => void) | null;
    onClick?: (value: FormEvent<HTMLInputElement>) => void;
    validators?: ValidatorFuncType[];
    checked?: boolean;
    id?: string;
    shouldDisableLabel?: boolean;
    size?: FormSize;
    step?: number;
    company?: Company;
    max?: string | null;
    dateFormat?: string;
}

interface ILabelInternalProps extends IHaveChildrenProps, IHaveOptionalClassName {
    id: string;
    forKey: string;
    text: string;
    fieldType: FormControlType;
    internalContainerClassName?: string;
}

const LabelInternal = ({
    id,
    text,
    className,
    internalContainerClassName,
    forKey,
    children,
    fieldType,
}: ILabelInternalProps) => {
    if (!text && !className) {
        return (
            <div>
                {children}
            </div>
        );
    }

    const containerClassname = concatClassNames(
        fieldType !== FormControlType.checkbox || FormControlType.radio
            ? styles.formControlContainer
            : null,
        internalContainerClassName,
    );
    const componentClassName = concatClassNames(
        fieldType !== FormControlType.checkbox || FormControlType.radio
            ? styles.formLabel
            : null,
        className,
    );

    return (
        <div className={containerClassname}>
            {children}
            <label id={id} className={componentClassName} htmlFor={forKey}>
                <span>{text}</span>
            </label>
        </div>
    );
};

const FormControl = ({
    name,
    value = '',
    onChange = null,
    onInput = null,
    validators = [],
    onClick,
    className = '',
    containerClassName = '',
    labelText = '',
    labelClassName = '',
    type = FormControlType.text,
    checked = false,
    id = generateId(),
    shouldDisableLabel = true,
    size = FormSize.default,
    step = 1,
    company = Company.Address,
    max = null,
    dateFormat = defaultDateFormat,
}: IFormControlProps) => {
    const [ formControlValue, setFormControlValue ] = useState(value);

    const [ isChecked, setIsChecked ] = useState<boolean>(checked);

    const [ isValid, setValid ] = useState(true);

    const { state: { validationValue } } = useValidationTrigger();

    const sizeClassName = size === FormSize.half
        ? styles.half
        : null;

    const componentClassName = concatClassNames(
        styles.formControl,
        className,
        sizeClassName,
        styles[company],
    );

    const validationClassName = useMemo(
        () => isValid
            ? ''
            : styles.invalid,
        [ isValid ],
    );

    const maxProp = useMemo(
        () => isNil(max)
            ? {}
            : { max },
        [ max ],
    );

    const validate = useCallback(
        async () => {
            const validationResults = await Promise.all(validators.map((validatorFunc) => validatorFunc(formControlValue)));

            setValid(validationResults.every((validationResult) => validationResult));
        },
        [ formControlValue, validators ],
    );

    const handleOnChange = useCallback(
        async (ev: ChangeEvent<TextAreaOrInputElement>) => {
            if (type === FormControlType.checkbox) {
                setIsChecked(!isChecked);

                return;
            }

            if (!isValid) {
                return;
            }

            const newValue = type === FormControlType.number
                ? parseFloat(ev.target.value)
                : type === FormControlType.date
                    ? moment(ev.target.value).format(dateFormat)
                    : ev.target.value;

            setFormControlValue(newValue.toString());

            if (isNil(onChange)) {
                return;
            }

            onChange(newValue);
        },
        [ dateFormat, isChecked, isValid, onChange, type ],
    );

    const handleOnInput = useCallback(
        async (ev: FormEvent<TextAreaOrInputElement>) => {
            const element = ev.target as TextAreaOrInputElement;

            setFormControlValue(element.value);

            if (isNil(onInput)) {
                return;
            }

            onInput(element.value);
        },
        [ onInput ],
    );

    const generateFormControl = useCallback(
        () => {
            if (type === FormControlType.textarea) {
                return (
                    <textarea
                      className={concatClassNames(componentClassName, styles.formControlTextArea, validationClassName)}
                      name={name}
                      id={id}
                      onChange={handleOnChange}
                      onInput={handleOnInput}
                      value={formControlValue}
                      placeholder={labelText}
                    />
                );
            }

            if (type === FormControlType.checkbox) {
                return (
                    <input
                      type={type}
                      className={concatClassNames(componentClassName, validationClassName)}
                      name={name}
                      id={id}
                      value={value}
                      checked={isChecked}
                      onClick={onClick}
                      onChange={handleOnChange}
                    />
                );
            }

            if (type === FormControlType.file) {
                return (
                    <input
                      type={type}
                      className={concatClassNames(componentClassName, styles.fileInput, validationClassName)}
                      name={name}
                      id={id}
                      onChange={(e) => {
                          if (isNil(onChange)) {
                              return;
                          }

                          const fileBlob = e.target.files?.item(0) as Blob;
                          onChange(fileBlob);
                      }}
                    />
                );
            }

            return (
                <input
                  type={type}
                  className={concatClassNames(componentClassName, styles.placeholderShown, validationClassName)}
                  name={name}
                  id={id}
                  onChange={handleOnChange}
                  onInput={handleOnInput}
                  value={formControlValue}
                  checked={checked}
                  placeholder={labelText}
                  step={step}
                  onBlur={validate}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                  {...maxProp}
                />
            );
        },
        [
            checked, componentClassName, formControlValue,
            handleOnChange, handleOnInput, id, isChecked,
            labelText, maxProp, name, onChange, onClick,
            step, type, validate, validationClassName, value,
        ],
    );

    useEffect(
        () => {
            if (validationValue <= 1) {
                return;
            }

            (async () => {
                await validate();
            })();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [ validationValue ],
    );

    const generateFormControlWithLabel = useCallback(
        () => (
            <LabelInternal
              id={`${id}-label`}
              text={labelText}
              fieldType={type}
              internalContainerClassName={containerClassName}
              className={labelClassName}
              forKey={id}
            >
                {generateFormControl()}
            </LabelInternal>
        ),
        [ containerClassName, generateFormControl, id, labelClassName, labelText, type ],
    );

    const renderFormControl = useCallback(
        () => shouldDisableLabel
            ? generateFormControl()
            : generateFormControlWithLabel(),
        [ shouldDisableLabel, generateFormControlWithLabel, generateFormControl ],
    );

    return (
        renderFormControl()
    );
};

export default FormControl;

export type {
    IFormControlOnChangeValueType,
};

export {
    FormControlType,
    FormSize,
};
