import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import isNil from 'lodash/isNil';

import { IBankAccountDetails } from '../common/accounts';
import { IHaveChildrenProps } from '../components/common/Props';

import { useCompanies } from './use-companies';
import { useDrafts } from './use-drafts';
import { useServices } from './use-services';

interface IBankAccountContext {
    state: {
        bankAccountDetails: IBankAccountDetails;
    };
}

interface IBankAccountProviderProps extends IHaveChildrenProps {
}

const BankAccountContext = createContext<IBankAccountContext>({ state: {} } as IBankAccountContext);

const defaultState = {} as IBankAccountDetails;

const BankAccountProvider = ({ children }: IBankAccountProviderProps) => {
    const [ bankAccountDetails, setBankAccountDetails ] = useState(defaultState);
    const { bankAccountsService } = useServices();
    const { state: { company } } = useCompanies();

    const { state: { draft } } = useDrafts();

    useEffect(
        () => {
            if (isNil(draft.paymentType)) {
                return;
            }
            (async () => {
                try {
                    const details = await bankAccountsService.getBankAccountDetails(draft.paymentType);
                    setBankAccountDetails(details);
                } catch (error) { /* empty */ }
            })();
        },
        [ bankAccountsService, company, draft.paymentType ],
    );

    const value = useMemo(
        () => ({ state: { bankAccountDetails } }),
        [ bankAccountDetails ],
    );

    return (
        <BankAccountContext.Provider value={value}>
            {children}
        </BankAccountContext.Provider>
    );
};

const useBankAccount = () => useContext(BankAccountContext);

export default BankAccountProvider;

export {
    useBankAccount,
};
