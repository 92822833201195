// eslint-disable-next-line prefer-destructuring
const baseApi = import.meta.env.VITE_API_URL?.trim();

// const baseApi = 'http://localhost:5027/api/Draft';

// Draft
const baseDraftApi = `${baseApi}/Draft`;

const createDraftPaymentUrl = `${baseDraftApi}/CreateDraftPayment`;
const updateDraftDetailsUrl = `${baseDraftApi}/UpdateDraftPersonalDetails/`;
const getCurrentDraftUrl = `${baseDraftApi}/GetDraftById`;
const updateDraftPaymentDetailsUrl = `${baseDraftApi}/UpdatePaymentCardDetails/`;
const getPaymentForm = `${baseDraftApi}/GetPaymentForm/`;
const createBoricaModelUrl = `${baseDraftApi}/CreateBoricaModel/`;

const completeDraftBankPaymentUrl = `${baseDraftApi}/CompleteBankAccountPayment/`;

// Bank accounts
const baseBankAccountsApi = `${baseApi}/BankAccount`;

const getBankAccountDetailsUrl = `${baseBankAccountsApi}/GetDetailsByPaymentType`;

// Stripe accounts

const baseStripeAccountsApi = `${baseApi}/StripeAccount`;

const getStripeAccountDetailsUrl = `${baseStripeAccountsApi}/GetDetails`;

export {
    createDraftPaymentUrl,
    updateDraftDetailsUrl,
    getCurrentDraftUrl,
    updateDraftPaymentDetailsUrl,
    getPaymentForm,
    createBoricaModelUrl,
    getBankAccountDetailsUrl,

    completeDraftBankPaymentUrl,

    getStripeAccountDetailsUrl,
};
