import concatClassNames from '../../utils/class-names';
import { IHaveChildrenProps, IHaveOptionalClassName } from '../common/Props';

import styles from './FieldContainer.module.scss';

interface IFieldContainerProps extends IHaveOptionalClassName, IHaveChildrenProps {
}

const FieldContainer = ({ children, className = '' }: IFieldContainerProps) => {
    const fieldContainerClassName = concatClassNames(styles.container, className);

    return (
        <div className={fieldContainerClassName}>
            {children}
        </div>
    );
};

export default FieldContainer;
