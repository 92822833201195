import { useCallback, useEffect, useMemo, useState } from 'react';
import { MenuItem, Select } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
// import moment from 'moment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import isNil from 'lodash/isNil';
import moment from 'moment';

import { IDictionary } from '../../common/common-types';
import { DEFAULT_DATE_FORMAT } from '../../common/dates';
import { PaymentType, PaymentTypesNames } from '../../common/payments';
import { useCompanies } from '../../hooks/use-companies';
import { paymentPlaceholder, useDrafts } from '../../hooks/use-drafts';
import { useServices } from '../../hooks/use-services';
import { getKeys } from '../../utils/enum-utils';
import Field from '../field/Field';
import FieldsContainer from '../guidelines/fields-container/FieldsContainer';
import FormControl, { FormControlType, FormSize, IFormControlOnChangeValueType } from '../guidelines/forms/FormControl';
import Heading, { HeadingType } from '../guidelines/headings/Heading';
import InputLabel, { LabelType } from '../guidelines/labels/Label';
import RequiredSign from '../required-sign/RequiredSign';

import styles from './PaymentDetails.module.scss';

enum DraftLabel {
    paymentType = 'Тип на плащане',
    offerCode = 'Код на оферта',
    address = 'Адрес',
    dateDeposit = 'Дата на договор за гаранция',
    dateCommission = 'Дата на договор за обслужване',
    signatory = 'Име на лице по договора',
    consulant = 'Обслужваща кантора',
    amount = 'Сума в лв.',
    city = 'Населено място',
}

enum DraftFormNames {
    offerCode = 'offerCode',
    city = 'city',
    address = 'address',
    date = 'date',
    signatory = 'signatory',
    consultant = 'consultant',
    amount = 'amount',
}

const mapPaymentTypeToName = {
    [PaymentType.purchaseDeposit]: PaymentTypesNames.purchaseDeposit,
    [PaymentType.purchaseCommission]: PaymentTypesNames.purchaseCommission,
    [PaymentType.salesCommission]: PaymentTypesNames.salesCommission,
    [PaymentType.rentDeposit]: PaymentTypesNames.rentDeposit,
    [PaymentType.rentCommission]: PaymentTypesNames.rentCommission,
};

const getPaymentTypeName = (paymentType: PaymentType): string => mapPaymentTypeToName[paymentType] || '';

const PaymentDetails = () => {
    const [ dateLabel, setDateLabel ] = useState(DraftLabel.dateCommission);
    const { state: { company } } = useCompanies();

    const { draftValidationService } = useServices();

    const {
        state: { draft },
        actions: {
            setPaymentAmount,
            setContractDate,
            setPaymentType,
            setPropertyCity,
            setOfferCode,
            setPropertyAddress,
            setSignatory,
            setConsultant,
        },
    } = useDrafts();

    useEffect(() => {
        if (draft.paymentType && (draft.paymentType.toString() === '0' || draft.paymentType.toString() === '3')) {
            setDateLabel(DraftLabel.dateDeposit);
        } else {
            setDateLabel(DraftLabel.dateCommission);
        }
    }, [ draft.paymentType ]);

    const handleOnChangeCodeOffer = useCallback((value?: IFormControlOnChangeValueType) => {
        setOfferCode(isNil(value)
            ? ''
            : value.toString());
    }, [ setOfferCode ]);

    const handlePaymentSelectOnChange = (value: any) => setPaymentType(value === 'Избери тип на плащане'
        ? ''
        : value);

    const handleCityOnChange = useCallback((value?: IFormControlOnChangeValueType) => setPropertyCity(isNil(value)
        ? ''
        : value.toString()), [ setPropertyCity ]);

    const handleOnChangeAddress = useCallback((value?: IFormControlOnChangeValueType) => setPropertyAddress(isNil(value)
        ? ''
        : value.toString()), [ setPropertyAddress ]);

    const handleOnChangeSignatory = useCallback((value?: IFormControlOnChangeValueType) => setSignatory(isNil(value)
        ? ''
        : value.toString()), [ setSignatory ]);

    const handleOnChangeConsultant = useCallback((value?: IFormControlOnChangeValueType) => setConsultant(isNil(value)
        ? ''
        : value.toString()), [ setConsultant ]);

    const handleOnChangeDate = useCallback((value?: any) => setContractDate(isNil(value)
        ? null
        : new Date(value.toString())), [ setContractDate ]);

    const handleOnChangeAmount = useCallback((value?: IFormControlOnChangeValueType) => setPaymentAmount(isNil(value)
        ? null
        : Number(value)), [ setPaymentAmount ]);

    const renderPaymentTypes = useCallback(
        () => getKeys(PaymentType)
            .map((k: any) => {
                // eslint-disable-next-line prefer-destructuring
                const key: any = PaymentType[k];
                // eslint-disable-next-line prefer-destructuring
                const value = (PaymentTypesNames as IDictionary<string>)[key];

                return (
                    <MenuItem
                      key={key}
                      value={k}
                    >
                        {value}
                    </MenuItem>
                );
            }),
        [],
    );

    const contractDateAsDate = useMemo(
        () => draft.contractDate
            ? moment(draft.contractDate, DEFAULT_DATE_FORMAT)
            : null,
        [ draft.contractDate ],
    );

    return (
        <div className={styles.wrapper}>
            <Heading type={HeadingType.primary} company={company}>1. ДАННИ ЗА ПЛАЩАНЕ</Heading>
            <FieldsContainer>
                <Field>
                    <InputLabel type={LabelType.realto}>
                        {DraftLabel.paymentType}
                        <RequiredSign />
                    </InputLabel>
                    <Select
                      sx={{
                          width: '100%',
                          height: 40,
                          border: '1px solid gray',
                          color: isNil(draft.paymentType)
                              ? 'gray'
                              : 'black',
                          borderRadius: 2,
                          marginTop: 0,
                          fontSize: 14,
                          transition: 'all .2s ease-in-out',
                          '&& fieldset': { border: 'none' },
                          '&:hover': { backgroundColor: '#e3f3fd' },
                      }}
                      value={draft.paymentType || ''}
                      onChange={(e) => handlePaymentSelectOnChange(e.target.value)}
                      displayEmpty
                      renderValue={isNil(draft.paymentType)
                          ? () => paymentPlaceholder
                          : () => getPaymentTypeName(draft.paymentType)}
                    >
                        {renderPaymentTypes()}
                    </Select>
                </Field>
                <Field>
                    <InputLabel type={LabelType.realto}>
                        {DraftLabel.offerCode}
                        <RequiredSign />
                    </InputLabel>
                    <FormControl
                      id={DraftFormNames.offerCode}
                      name={DraftFormNames.offerCode}
                      type={FormControlType.input}
                      onChange={handleOnChangeCodeOffer}
                      value={draft.offerCode}
                      labelText="Въведете код на оферта"
                      validators={[ (value) => draftValidationService.hasValidOfferCode(value) ]}
                    />
                </Field>
                <Field>
                    <InputLabel type={LabelType.realto}>
                        {DraftLabel.amount}
                        <RequiredSign />
                    </InputLabel>
                    <FormControl
                      id={DraftFormNames.amount}
                      name={DraftFormNames.amount}
                      value={draft.paymentAmount?.toString()}
                      labelText="Въведете сума в лв."
                      type={FormControlType.number}
                      onChange={(value) => handleOnChangeAmount(value)}
                      size={FormSize.half}
                      step={100}
                      validators={[ (value) => draftValidationService.hasValidPaymentAmount(value) ]}
                    />
                </Field>
            </FieldsContainer>
            <Heading className={styles.alignTitle} type={HeadingType.secondary}>Адрес на имот</Heading>
            <FieldsContainer>
                <Field>
                    <InputLabel type={LabelType.realto}>
                        {DraftLabel.city}
                    </InputLabel>
                    <FormControl
                      id={DraftFormNames.city}
                      name={DraftFormNames.city}
                      value={draft.propertyCity}
                      labelText="Въведете населено място"
                      type={FormControlType.input}
                      onChange={handleCityOnChange}
                      validators={[ (value) => draftValidationService.hasValidPropertyCity(value) ]}
                    />
                </Field>
                <Field>
                    <InputLabel type={LabelType.realto}>
                        {DraftLabel.address}
                    </InputLabel>
                    <FormControl
                      id={DraftFormNames.address}
                      name={DraftFormNames.address}
                      value={draft.propertyAddress}
                      labelText="Въведете точен адрес"
                      type={FormControlType.input}
                      onChange={handleOnChangeAddress}
                      validators={[ (value) => draftValidationService.hasValidPropertyAddress(value) ]}
                    />
                </Field>
                <Field>
                    <InputLabel type={LabelType.realto}>
                        {dateLabel}
                    </InputLabel>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                          className={styles.datePicker}
                            //   label={dateLabel}
                          format={DEFAULT_DATE_FORMAT}
                          value={contractDateAsDate}
                          onChange={handleOnChangeDate}
                          maxDate={moment()}
                        />
                    </LocalizationProvider>
                </Field>
                <Field>
                    <InputLabel type={LabelType.realto}>
                        {DraftLabel.signatory}
                        <RequiredSign />
                    </InputLabel>
                    <FormControl
                      id={DraftFormNames.signatory}
                      name={DraftFormNames.signatory}
                      value={draft.signatory}
                      labelText="Въведете име на лице по договора"
                      type={FormControlType.input}
                      onChange={handleOnChangeSignatory}
                      validators={[ (value) => draftValidationService.hasValidSignatory(value) ]}
                    />
                </Field>
                <Field>
                    <InputLabel type={LabelType.realto}>
                        {DraftLabel.consulant}
                        <RequiredSign />
                    </InputLabel>
                    <FormControl
                      id={DraftFormNames.consultant}
                      name={DraftFormNames.consultant}
                      value={draft.consultant}
                      labelText="Въведе име на oбслужваща кантора"
                      type={FormControlType.input}
                      size={FormSize.half}
                      onChange={(...args) => handleOnChangeConsultant(...args)}
                      validators={[ (value) => draftValidationService.hasValidConsultant(value) ]}
                    />
                </Field>
            </FieldsContainer>
        </div>
    );
};
export default PaymentDetails;
