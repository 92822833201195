import { IStripeAccountDetails } from '../common/accounts';
import { getStripeAccountDetailsUrl } from '../utils/urls';

import HttpService from './http-service';

class StripeAccountsService {
    httpService: HttpService;

    constructor(httpService: HttpService) {
        this.httpService = httpService;
    }

    async getDetails(): Promise<IStripeAccountDetails> {
        const { data } = await this.httpService.get(getStripeAccountDetailsUrl);
        return data;
    }
}

export default StripeAccountsService;
