import React from 'react';
import isNil from 'lodash/isNil';

import { Company } from '../../../hooks/use-companies';
import concatClassNames from '../../../utils/class-names';
import { IHaveOptionalChildrenProps, IHaveOptionalClassName } from '../../common/Props';

import styles from './Text.module.scss';

enum TextType {
    Normal = 0,
    Bold = 1,
    Italic = 2,
    Underlined = 3,
    Muted = 4,
}

interface ITextProps extends IHaveOptionalChildrenProps, IHaveOptionalClassName {
    text?: string;
    type?: TextType;
    company?: Company;
}

const typeClassNamesMap = {
    [TextType.Normal]: styles.normal,
    [TextType.Bold]: styles.bold,
    [TextType.Italic]: styles.italic,
    [TextType.Underlined]: styles.underlined,
    [TextType.Muted]: styles.muted,
};

const componentMap = {
    [TextType.Normal]: 'span',
    [TextType.Bold]: 'strong',
    [TextType.Italic]: 'em',
    [TextType.Underlined]: 'u',
    [TextType.Muted]: 'span',
};

const Text = ({
    text = '',
    children = null,
    type = TextType.Normal,
    className = '',
    company = Company.Address,
}: ITextProps) => {
    if (isNil(text) && isNil(children)) {
        throw new Error('Texts must have only `text` or `children`');
    }

    const content = children ?? text;

    return React.createElement(
        componentMap[type],
        { className: concatClassNames(styles.text, typeClassNamesMap[type], className, styles[company]) },
        content,
    );
};

export default Text;

export {
    TextType,
};
