import * as React from 'react';
import {
    createContext,
    useCallback,
    useContext,
    useMemo,
    useState,
} from 'react';

import { IHaveChildrenProps } from '../components/common/Props';

interface INavigationProviderProps extends IHaveChildrenProps {
}

interface INavigationContextType {
    page: number;
    goForward: () => void;
    goBackward: () => void;
}

const defaultNavigationContext = {
    page: 1,
    goForward: () => { },
    goBackward: () => { },
};

const NavigationContext = createContext<INavigationContextType>(defaultNavigationContext);

const useNavigation = () => useContext(NavigationContext);

const MAX_PAGE = 4;

const NavigationProvider = ({ children }: INavigationProviderProps) => {
    const [ page, setPage ] = useState(1);
    const goForward = useCallback(
        () => {
            setPage((prev) => {
                if (prev < MAX_PAGE) {
                    return prev + 1;
                }
                return prev;
            });
        },
        [],
    );

    const goBackward = React.useCallback(
        () => {
            setPage((prev) => prev - 1);
        },
        [ ],
    );

    const value = useMemo(() => ({
        page,
        goForward,
        goBackward,
    }), [ goBackward, goForward, page ]);

    return (
        <NavigationContext.Provider value={value}>
            {children}
        </NavigationContext.Provider>
    );
};

export { useNavigation };
export default NavigationProvider;
