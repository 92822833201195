import endsWith from 'lodash/endsWith';

class UrlsBuilderService {
    getForRoute(route: string) {
        const {
            location: {
                protocol,
                pathname,
                host,
            },
        } = window;

        const slash = endsWith(pathname, '/')
            ? ''
            : '/';

        const path = `${protocol}//${host}${pathname}${slash}${route}`;
        console.log(path);
        return path;
    }
}

export default UrlsBuilderService;
