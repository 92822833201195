import { useCompanies } from '../../hooks/use-companies';

import { companiesLinks, LinkType } from './companies-links';

import styles from './FooterLinks.module.scss';

const TermsOfUseButton = () => {
    const { state: { company } } = useCompanies();

    const { [company]: companyLinks } = companiesLinks;
    const { [LinkType.TermsOfUse]: link } = companyLinks;

    return (
        <a className={styles.link} href={link}>Общи условия</a>
    );
};

export default TermsOfUseButton;
