import isEmpty from 'lodash/isEmpty';
import isNaN from 'lodash/isNaN';
import isNil from 'lodash/isNil';
import moment from 'moment';

import { DEFAULT_DATE_FORMAT } from '../common/dates';
import { PaymentType } from '../common/payments';
import { IDraftType } from '../contexts/types/types';
import { EmailRegex, PersonalIdentificationNumberRegex, PhoneNumberRegex } from '../utils/constants';

class DraftValidationService {
    validationValue: number;

    constructor(validationValue: number) {
        this.validationValue = validationValue;
    }

    isValid(draft: IDraftType) {
        return this.isValidForPaymentDetails(draft) && this.isValidForPersonalDetails(draft);
    }

    isValidForPaymentDetails(draft: IDraftType) {
        const {
            paymentType,
            offerCode,
            propertyCity,
            propertyAddress,
            signatory,
            consultant,
            paymentAmount,
            contractDate,
        } = draft;

        const results = [
            this.hasValidPaymentType(paymentType),
            this.hasValidOfferCode(offerCode),
            this.hasValidPropertyCity(propertyCity),
            this.hasValidPropertyAddress(propertyAddress),
            this.hasValidSignatory(signatory),
            this.hasValidConsultant(consultant),
            this.hasValidPaymentAmount(paymentAmount),
            this.hasValidContractDate(contractDate),
        ];

        return results.every((x) => x);
    }

    isValidForPersonalDetails(draft: IDraftType) {
        const { isLegalEntity } = draft;

        return this.isValidForNonLegalEntityPaymentDetails(draft) &&
            isLegalEntity
            ? this.isValidForLegalEntityPaymentDetails(draft)
            : true;
    }

    public isValidForLegalEntityPaymentDetails(draft: IDraftType) {
        const {
            nameOfCompany,
            financiallyLiablePerson,
            bulstat,
            address,
        } = draft;

        return this.hasValidNameOfCompany(nameOfCompany) &&
            this.hasValidFinanciallyLiablePerson(financiallyLiablePerson) &&
            this.hasValidBulstat(bulstat) &&
            this.hasValidAddress(address);
    }

    public isValidForNonLegalEntityPaymentDetails(draft: IDraftType): boolean {
        const {
            name,
            personalIdentificationNumber,
            phone,
            email,
            customerAddress,
        } = draft;

        return this.hasValidName(name) &&
            this.hasValidPersonalIdentificationNumber(personalIdentificationNumber) &&
            this.hasValidPhone(phone) &&
            this.hasValidEmail(email) &&
            this.hasValidCustomerAddress(customerAddress);
    }

    public hasValidPaymentType(paymentType: number) {
        return !isNil(PaymentType[paymentType]);
    }

    public hasValidOfferCode(offerCode: string | undefined) {
        return !isNil(offerCode) && !isEmpty(offerCode);
    }

    public hasValidPropertyCity(propertyCity: string | undefined) {
        return !isNil(propertyCity) && !isEmpty(propertyCity);
    }

    public hasValidPropertyAddress(propertyAddress: string | undefined) {
        return !isNil(propertyAddress) && !isEmpty(propertyAddress);
    }

    public hasValidSignatory(signatory: string | undefined) {
        return !isNil(signatory) && !isEmpty(signatory);
    }

    public hasValidConsultant(consultant: string | undefined) {
        return !isNil(consultant) && !isEmpty(consultant);
    }

    public hasValidPaymentAmount(paymentAmount: number | undefined) {
        if (isNil(paymentAmount)) {
            return false;
        }
        const paymentAmountNumber = parseFloat(paymentAmount.toString());

        return !isNaN(paymentAmountNumber) && paymentAmountNumber > 0;
    }

    public hasValidContractDate(contractDate: Date | undefined) {
        return !isNil(contractDate) && moment(contractDate, DEFAULT_DATE_FORMAT).isValid();
    }

    public hasValidName(name: string | undefined) {
        return !isNil(name) && !isEmpty(name);
    }

    public hasValidPersonalIdentificationNumber(personalIdentificationNumber: string | undefined) {
        return !isNil(personalIdentificationNumber) && PersonalIdentificationNumberRegex.test(personalIdentificationNumber);
    }

    public hasValidPhone(phone: string | undefined) {
        return !isNil(phone) && PhoneNumberRegex.test(phone);
    }

    public hasValidEmail(email: string | undefined) {
        return !isNil(email) && EmailRegex.test(email);
    }

    public hasValidCustomerAddress(customerAddress: string | undefined) {
        return !isNil(customerAddress);
    }

    public hasValidIsLegalEntity(isLegalEntity: boolean | undefined) {
        return !isNil(isLegalEntity);
    }

    public hasValidNameOfCompany(nameOfCompany: string | undefined) {
        return !isNil(nameOfCompany);
    }

    public hasValidFinanciallyLiablePerson(financiallyLiablePerson: string | undefined) {
        return !isNil(financiallyLiablePerson);
    }

    public hasValidBulstat(bulstat: string | undefined) {
        return !isNil(bulstat);
    }

    public hasValidAddress(address: string | undefined) {
        return !isNil(address);
    }
}

export default DraftValidationService;
