import { useCallback } from 'react';

import leftArrow from '../../../assets/button/left-arrow.svg';
import rightArrow from '../../../assets/button/right-arrow.svg';
import { useCompanies } from '../../../hooks/use-companies';
import { usePayment } from '../../../hooks/use-payment';
import concatClassNames from '../../../utils/class-names';
import Button, { ButtonMove, ButtonSize, ButtonType } from '../../guidelines/buttons/Button';
import Text, { TextType } from '../../guidelines/text/Text';

import styles from './NavigationButtons.module.scss';

interface INavigationButtonsProps {
    hasNext?: boolean;
    hasPrev?: boolean;
    isFinalPage?: boolean;
    onBackward: () => void;
    onForward: () => void;
}

const NavigationButtons = ({
    hasNext = false,
    hasPrev = false,
    isFinalPage = false,
    onBackward,
    onForward,
}: INavigationButtonsProps) => {
    const { state: { error } } = usePayment();
    const { state: { company } } = useCompanies();
    const renderBackButton = useCallback(
        () => hasPrev
            ? (
                <Button
                  imgSrc={leftArrow}
                  size={ButtonSize.medium}
                  type={ButtonType.secondary}
                  move={ButtonMove.left}
                  text="Назад"
                  onClick={onBackward}
                  company={company}
                />
            )
            : null,
        [ onBackward, hasPrev, company ],
    );

    const renderForwardButton = useCallback(
        () => hasNext
            ? (
                <Button
                  imgSrc={rightArrow}
                  size={ButtonSize.medium}
                  type={ButtonType.secondary}
                  move={ButtonMove.right}
                  text="Продължи"
                  onClick={onForward}
                  company={company}
                />
            )
            : null,
        [ onForward, hasNext, company ],
    );

    const containerClassname = concatClassNames(styles.container, hasPrev
        ? ''
        : styles.alignButton);

    if (isFinalPage) {
        return null;
    }

    return (
        <>
            <span className={styles.errorContainer}>{error}</span>
            <div className={containerClassname}>
                {renderBackButton()}
                {renderForwardButton()}
            </div>
            <Text className={styles.footerText} type={TextType.Normal} text="© 1994 - 2024 Адрес, All rights reserved" />
        </>
    );
};

export default NavigationButtons;
