import concatClassNames from '../../utils/class-names';
import { IHaveChildrenProps, IHaveOptionalClassName } from '../common/Props';

import styles from './Field.module.scss';

interface IFieldProps extends IHaveChildrenProps, IHaveOptionalClassName {
}

const Field = ({ children, className = '' }: IFieldProps) => {
    const theClassName = concatClassNames(styles.field, className);

    return (
        <div className={theClassName}>
            {children}
        </div>
    );
};

export default Field;
