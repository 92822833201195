import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import moment from 'moment';

import { PaymentMethod } from '../common/accounts';
import { IHaveChildrenProps } from '../components/common/Props';
import { completeDraftBankPaymentUrl, createDraftPaymentUrl, updateDraftDetailsUrl, updateDraftPaymentDetailsUrl } from '../utils/urls';

import { useCompanies } from './use-companies';
import { useDrafts } from './use-drafts';
import { useServices } from './use-services';
import { useValidationTrigger } from './use-validation-trigger';

interface IPaymentContext {
    state: {
        id: number | null;
        error: string;
    };
    actions: {
        saveDraft: (onSuccess: () => void) => Promise<void>;
        updateDraftDetails: (onSuccess: () => void) => Promise<void>;
        updateDraftPaymentDetails: () => Promise<void>;
        setError: React.Dispatch<React.SetStateAction<string>>;
    };
}

const PaymentContext = createContext<IPaymentContext>({} as IPaymentContext);

type IPaymentProviderProps = IHaveChildrenProps

const PaymentProvider = ({ children }: IPaymentProviderProps) => {
    const [ id, setId ] = useState<number>(0);
    const [ error, setError ] = useState('');
    const { state: { company } } = useCompanies();

    const { httpService, draftValidationService } = useServices();
    const { state: { draft, paymentMethod } } = useDrafts();

    const { actions: { trigger } } = useValidationTrigger();

    const saveDraft = useCallback(
        async (onSuccess: () => void) => {
            const isValid = await draftValidationService.isValidForPaymentDetails(draft);
            if (!isValid) {
                trigger();
                return;
            }

            draft.company = company;
            const contractDate = moment(draft.contractDate, 'YYYY-MM-DD').toDate();

            const payload = {
                ...draft,
                contractDate,
            };

            const { data } = await httpService.post(createDraftPaymentUrl, payload);
            setId(data);
            onSuccess();
        },
        [ draftValidationService, draft, company, httpService, trigger ],
    );

    const updateDraftDetails = useCallback(
        async (onSuccess: () => void) => {
            const isValid = await draftValidationService.isValidForPersonalDetails(draft);

            if (!isValid) {
                return;
            }

            await httpService.put(`${updateDraftDetailsUrl}${id}`, draft);
            onSuccess();
        },
        [ draftValidationService, draft, httpService, id ],
    );

    const updateDraftPaymentDetails = useCallback(
        async () => {
            if (paymentMethod === PaymentMethod.card) {
                await httpService.put(`${updateDraftPaymentDetailsUrl}${id}`, draft);
            } else {
                await httpService.post(`${completeDraftBankPaymentUrl}${id}`, draft);
            }
        },
        [ paymentMethod, httpService, id, draft ],
    );

    const value = useMemo(
        () => ({
            state: {
                id,
                draft,
                error,
            },
            actions: { saveDraft, updateDraftDetails, updateDraftPaymentDetails, setError },
        }),
        [
            id, draft, saveDraft, updateDraftDetails, updateDraftPaymentDetails, error, setError,
        ],
    );

    return (
        <PaymentContext.Provider value={value}>
            {children}
        </PaymentContext.Provider>
    );
};

const usePayment = () => useContext(PaymentContext);

export {
    usePayment,
};

export default PaymentProvider;
