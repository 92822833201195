const copyTextToClipboard = (text: string) => {
    if ('clipboard' in navigator) {
        return navigator.clipboard.writeText(text);
    }

    return document.execCommand('copy', true, text);
};

export {
    // eslint-disable-next-line import/prefer-default-export
    copyTextToClipboard,
};
