import { useMemo } from 'react';
import moment from 'moment';

import { DEFAULT_DATE_FORMAT } from '../../common/dates';
import { PaymentType } from '../../common/payments';
import { useCompanies } from '../../hooks/use-companies';
import { useDrafts } from '../../hooks/use-drafts';
import Field from '../field/Field';
import FieldContainer from '../field-container/FieldContainer';
import Heading, { HeadingType } from '../guidelines/headings/Heading';
import Text, { TextType } from '../guidelines/text/Text';
import RequiredSign from '../required-sign/RequiredSign';

import styles from './Preview.module.scss';

const payments = [ 'Депозит за покупка', 'Комисионна за покупка', 'Комисионна за продажба', 'Депозит за наем', 'Комисионна за наем' ];

const PreviewDetails = () => {
    const { state: { company } } = useCompanies();
    const { state: { draft } } = useDrafts();

    const dateLabel = useMemo(
        () => [ PaymentType.rentDeposit.toString(), PaymentType.purchaseDeposit.toString() ].includes(draft.paymentType.toString())
            ? 'Дата на Договор за гаранция'
            : 'Дата на Договор за обслужване',
        [ draft.paymentType ],
    );

    return (
        <div>
            <Heading type={HeadingType.primary} company={company}>3. Преглед на данните за плащане</Heading>
            <div className={styles.preview}>
                <FieldContainer>
                    <Field className={styles.formField}>
                        <Text type={TextType.Bold} text="Тип на плащане" />
                        <Text type={TextType.Normal} company={company} text={payments[draft.paymentType]} />
                    </Field>
                    <Field className={styles.formField}>
                        <Text type={TextType.Bold} text="Код на офертата" />
                        <Text type={TextType.Normal} company={company} text={draft.offerCode} />
                    </Field>
                </FieldContainer>
                <FieldContainer>
                    <Field className={styles.formField}>
                        <Text type={TextType.Bold} text="Сума в лв." />
                        <Text type={TextType.Normal} company={company} text={draft.paymentAmount?.toString()} />
                    </Field>
                </FieldContainer>
                <Heading className={styles.alignSecondaryTitle} type={HeadingType.secondary}>Адрес на имот</Heading>
                <FieldContainer>
                    <Field className={styles.formField}>
                        <Text type={TextType.Bold} text="Населено място" />
                        <Text type={TextType.Normal} company={company} text={draft.propertyCity} />
                    </Field>
                    <Field className={styles.formField}>
                        <Text type={TextType.Bold} text="Адрес" />
                        <Text type={TextType.Normal} company={company} text={draft.propertyAddress} />
                    </Field>
                </FieldContainer>
                <FieldContainer>
                    <Field className={styles.formField}>
                        <Text type={TextType.Bold} text={dateLabel} />
                        <Text
                          type={TextType.Normal}
                          company={company}
                          text={moment(draft.contractDate, DEFAULT_DATE_FORMAT).format(DEFAULT_DATE_FORMAT)}
                        />
                    </Field>
                    <Field className={styles.formField}>
                        <div>
                            <Text type={TextType.Bold} text="Имена на лице по договора" />
                            <RequiredSign />
                        </div>
                        <Text type={TextType.Normal} company={company} text={draft.signatory} />
                    </Field>
                </FieldContainer>
                <FieldContainer>
                    <Field className={styles.formField}>
                        <div>
                            <Text type={TextType.Bold} text="Oбслужваща кантора" />
                            <RequiredSign />
                        </div>
                        <Text type={TextType.Normal} company={company} text={draft.consultant} />
                    </Field>
                </FieldContainer>
            </div>
        </div>
    );
};
export default PreviewDetails;
