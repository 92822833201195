import {
    createContext,
    useContext,
    useMemo,
} from 'react';

import { IHaveChildrenProps } from '../components/common/Props';
import BankAccountsService from '../services/bank-accounts-service';
import DraftValidationService from '../services/draft-validation-service';
import HttpService from '../services/http-service';
import UrlsBuilderService from '../services/location-service';
import StripeAccountsService from '../services/stripe-accounts-service';

import { useCompanies } from './use-companies';
import { useValidationTrigger } from './use-validation-trigger';

interface IServicesContext {
    httpService: HttpService;
    bankAccountsService: BankAccountsService;
    stripeAccountsService: StripeAccountsService;
    draftValidationService: DraftValidationService;
    urlsBuilderService: UrlsBuilderService;
}

const defaultState = { };

const ServicesContext = createContext<IServicesContext>(defaultState as IServicesContext);

type IServicesProviderProps = IHaveChildrenProps

const ServicesProvider = ({ children }: IServicesProviderProps) => {
    const { state: { validationValue } } = useValidationTrigger();
    const { state: { company } } = useCompanies();
    const httpService = useMemo(
        () => new HttpService(company),
        [ company ],
    );

    const bankAccountsService = useMemo(
        () => new BankAccountsService(httpService),
        [ httpService ],
    );

    const draftValidationService = useMemo(
        () => new DraftValidationService(validationValue),
        [ validationValue ],
    );

    const urlsBuilderService = useMemo(
        () => new UrlsBuilderService(),
        [],
    );

    const stripeAccountsService = useMemo(
        () => new StripeAccountsService(httpService),
        [ httpService ],
    );

    const value = useMemo(
        () => ({
            httpService,
            bankAccountsService,
            draftValidationService,
            urlsBuilderService,
            stripeAccountsService,
        }),
        [ httpService, bankAccountsService, draftValidationService, urlsBuilderService, stripeAccountsService ],
    );

    return (
        <ServicesContext.Provider value={value}>
            {children}
        </ServicesContext.Provider>
    );
};

const useServices = () => useContext(ServicesContext);

export {
    useServices,
};

export default ServicesProvider;
