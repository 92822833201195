import { IBankAccountDetails } from '../common/accounts';
import { PaymentType } from '../common/payments';
import { getBankAccountDetailsUrl } from '../utils/urls';

import HttpService from './http-service';

class BankAccountsService {
    httpService: HttpService;

    constructor(httpService: HttpService) {
        this.httpService = httpService;
    }

    async getBankAccountDetails(paymentType: PaymentType): Promise<IBankAccountDetails> {
        const url = `${getBankAccountDetailsUrl}?paymentType=${paymentType}`;

        const { data } = await this.httpService.get(url);
        return data;
    }
}

export default BankAccountsService;
