import { createContext, useCallback, useContext, useMemo, useState } from 'react';

import { IHaveChildrenProps } from '../components/common/Props';

interface IValidationTriggerContext {
    state: {
        validationValue: number;
    };
    actions: {
        trigger: () => void;
    };
}

interface IValidationTriggerContextProviderProps extends IHaveChildrenProps {
}

const ValidationTriggerContext = createContext<IValidationTriggerContext>({} as IValidationTriggerContext);

const ValidationTriggerProvider = ({ children }: IValidationTriggerContextProviderProps) => {
    const [ val, setVal ] = useState(1);

    const trigger = useCallback(
        () => setVal(val + 1),
        [ val ],
    );

    const value = useMemo(
        () => ({
            state: { validationValue: val },
            actions: { trigger },
        }),
        [ trigger, val ],
    );

    return (
        <ValidationTriggerContext.Provider value={value}>
            {children}
        </ValidationTriggerContext.Provider>
    );
};

const useValidationTrigger = () => useContext(ValidationTriggerContext);

export default ValidationTriggerProvider;

export {
    useValidationTrigger,
};
