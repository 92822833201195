import { useCallback, useMemo } from 'react';
import isNil from 'lodash/isNil';

import { useNavigation } from '../../../hooks/use-navigation';
import { usePayment } from '../../../hooks/use-payment';
import Payment from '../../payment/Payment';
import PaymentDetails from '../../payment-details/PaymentDetails';
import PersonalDetails from '../../personal-details/PersonalDetails';
import Preview from '../../preview/Preview';
import NavigationButtons from '../navigation-buttons/NavigationButtons';
import PaymentProgress, { PaymentProgressStep } from '../payment-progress/PaymentProgress';

import styles from './PaymentFlow.module.scss';

const PaymentFlow = () => {
    const { page, goBackward, goForward } = useNavigation();
    const {
        actions: {
            saveDraft,
            updateDraftDetails,
            updateDraftPaymentDetails,
        },
    } = usePayment();

    const {
        Component,
        step,
        action,
    } = useMemo(
        () => {
            switch (page) {
            case 2:
                return {
                    Component: PersonalDetails,
                    step: PaymentProgressStep.PersonalDetails,
                    action: updateDraftDetails,
                };
            case 3:
                return {
                    Component: Preview,
                    step: PaymentProgressStep.Preview,
                    action: goForward,
                };
            case 4:
                return {
                    Component: Payment,
                    step: PaymentProgressStep.Payment,
                    action: async () => {
                        await updateDraftPaymentDetails();
                    },
                };
            default:
                return {
                    Component: PaymentDetails,
                    step: PaymentProgressStep.PaymentDetails,
                    action: saveDraft,
                };
            }
        },
        [ page, saveDraft, updateDraftDetails, goForward, updateDraftPaymentDetails ],
    );

    const handleForward = useCallback(
        async () => {
            try {
                if (!isNil(action)) {
                    await action(goForward);
                }
            } catch (e) {
                console.log(e);
            }
        },
        [ action, goForward ],
    );

    const handleBackward = useCallback(
        () => goBackward(),
        [ goBackward ],
    );

    return (
        <div className={styles.paymentFlowContainer}>
            <PaymentProgress step={step} />
            <div style={{ flexGrow: 1 }}>
                <Component />
            </div>
            <NavigationButtons
              hasNext={page <= 4}
              hasPrev={page > 1}
              isFinalPage={page === 4}
              onForward={() => handleForward()}
              onBackward={() => handleBackward()}
            />
        </div>
    );
};

export default PaymentFlow;
