import { useCompanies } from '../../hooks/use-companies';

import { companiesLinks, LinkType } from './companies-links';

import styles from './FooterLinks.module.scss';

const PrivacyPolicyButton = () => {
    const { state: { company } } = useCompanies();

    const { [company]: companyLinks } = companiesLinks;
    const { [LinkType.PrivacyPolicy]: link } = companyLinks;

    return (
        <a className={styles.link} href={link}>Политики за поверителност</a>
    );
};

export default PrivacyPolicyButton;
