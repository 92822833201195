enum Company {
    Address = 'address',
    Imoteka = 'imoteka',
    NewEstates = 'new-estates',
    UniqueEstates = 'unique-estates',
}

export {
    // eslint-disable-next-line import/prefer-default-export
    Company,
};
