import { IHaveChildrenProps, IHaveOptionalClassName } from '../../common/Props';

import styles from './FieldsContainer.module.scss';

interface IFieldsContainerProps extends IHaveChildrenProps, IHaveOptionalClassName {
}

const FieldsContainer = ({ children }: IFieldsContainerProps) => (
    <div className={styles.fieldsContainer}>
        {children}
    </div>
);

export default FieldsContainer;
