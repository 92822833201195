import { useCallback, useMemo } from 'react';

import { useCompanies } from '../../../hooks/use-companies';
import { useNavigation } from '../../../hooks/use-navigation';
import concatClassNames from '../../../utils/class-names';
import List, { Orientation } from '../../guidelines/lists/List';

import styles from './PaymentProgress.module.scss';

enum PaymentProgressStep {
    PaymentDetails = 'Данни за плащане',
    PersonalDetails = 'Данни на клиента',
    Preview = 'Преглед на данни за плащане',
    Payment = 'Метод на плащане',
    PaymentComplete = 'Плащането е успешно',
}

interface IPaymentProgressProps {
    step: PaymentProgressStep;
}

const PaymentProgress = ({ step }: IPaymentProgressProps) => {
    const { page } = useNavigation();
    const { state: { company } } = useCompanies();

    const containerClassname = useMemo(() => concatClassNames(styles.progressListItem, styles[`${company}-progress`]), [ company ]);

    const itemFunc = (value: PaymentProgressStep) => (<div className={styles.progressItemText}>{value}</div>);
    const values = Object.values(PaymentProgressStep);
    const itemClassNameFunc = useCallback(
        (value: PaymentProgressStep, index: number) => {
            if (value === PaymentProgressStep.PaymentComplete) {
                return styles.paymentSuccessful;
            }

            return index === page - 1
                ? styles.active
                : index < page - 1
                    ? styles.done
                    : '';
        },
        [ page ],
    );

    return (
        <List
          orientation={Orientation.horizontal}
          fullWidth
          values={values}
          itemFunc={itemFunc}
          className={styles.progressList}
          itemClassName={containerClassname}
          itemClassNameFunc={itemClassNameFunc}
          key={step}
        />
    );
};

export default PaymentProgress;

export {
    PaymentProgressStep,
};
