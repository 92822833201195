import { useCallback, useMemo, useState } from 'react';
import Loading from 'react-loading';

import { PaymentMethod } from '../../common/accounts';
import { useCompanies } from '../../hooks/use-companies';
import { useDrafts } from '../../hooks/use-drafts';
import { wait } from '../../utils/promise-utils';
import Button, { ButtonType } from '../guidelines/buttons/Button';
import Heading, { HeadingType } from '../guidelines/headings/Heading';
import Text, { TextType } from '../guidelines/text/Text';

import AccountPayment from './AccountPayment';
import CardPayment from './CardPayment';

import styles from './Payment.module.scss';

const Payment = () => {
    const [ isLoading, setLoading ] = useState(false);

    const { state: { company } } = useCompanies();
    const {
        state: { paymentMethod },
        actions: { setPaymentMethod },
    } = useDrafts();

    const handleClick = useCallback(
        async (newPaymentMethod: PaymentMethod) => {
            setLoading(true);
            setPaymentMethod(newPaymentMethod);
            await wait(0.5);
            setLoading(false);
        },
        [ setPaymentMethod ],
    );

    const cardPaymentButtonType = useMemo(
        () => paymentMethod === PaymentMethod.card
            ? ButtonType.primary
            : ButtonType.secondary,
        [ paymentMethod ],
    );

    const bankAccountPaymentButtonType = useMemo(
        () => paymentMethod === PaymentMethod.bankAccount
            ? ButtonType.primary
            : ButtonType.secondary,
        [ paymentMethod ],
    );

    const renderButtons = useCallback(
        () => (
            <div className={styles.buttonsContainer}>
                <Button
                  className={styles.button}
                  type={cardPaymentButtonType}
                  onClick={() => handleClick(PaymentMethod.card)}
                  company={company}
                >
                    Онлайн плащане
                </Button>
                <Button
                  className={styles.button}
                  type={bankAccountPaymentButtonType}
                  onClick={() => handleClick(PaymentMethod.bankAccount)}
                  company={company}
                >
                    Банков превод
                </Button>
            </div>
        ),
        [ bankAccountPaymentButtonType, cardPaymentButtonType, company, handleClick ],
    );

    const renderLoading = useCallback(
        () => (
            <div className={styles.loadingContainer}>
                <Loading />
            </div>
        ),
        [],
    );

    const renderPaymentMethod = useCallback(
        () => isLoading
            ? renderLoading()
            : paymentMethod === PaymentMethod.card
                ? <CardPayment />
                : <AccountPayment />,
        [ isLoading, paymentMethod, renderLoading ],
    );

    return (
        <>
            <Heading type={HeadingType.primary} company={company}>4. Метод на плащане</Heading>
            {renderButtons()}
            <div className={styles.paymentMethodContainer}>
                {renderPaymentMethod()}
            </div>
            <div className={styles.footerContainer}>
                <Text
                  className={styles.footerText}
                  type={TextType.Normal}
                  company={company}
                  text="© 1994 - 2024 Адрес, All rights reserved"
                />
            </div>
        </>
    );
};

export default Payment;