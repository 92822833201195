import { createContext, useContext, useMemo } from 'react';

import { IHaveChildrenProps } from '../components/common/Props';
import { IDraftType } from '../contexts/types/types';
import { getQueryParams } from '../utils/url-utils';

import { useCurrentLocation } from './use-current-location';
import { IDictionary } from '../common/common-types';

interface IDefaultParamsContext {
    state: IDraftType;
}

interface IDefaultParamsProviderProps extends IHaveChildrenProps {
}

const DefaultParamsContext = createContext<IDefaultParamsContext>({ state: {} } as IDefaultParamsContext);

const DefaultParamsProvider = ({ children }: IDefaultParamsProviderProps) => {
    const currentUrl = useCurrentLocation();

    const defaultParams = useMemo(
        () => {
            const params = getQueryParams(currentUrl) as IDictionary<string>;
            const { isLegalEntity } = params;

            return {
                ...params,
                isLegalEntity: isLegalEntity === '1',
            } as IDraftType;
        },
        [ currentUrl ],
    );

    const value = useMemo(
        () => ({ state: { ...defaultParams } }),
        [ defaultParams ],
    );

    return (
        <DefaultParamsContext.Provider value={value}>
            {children}
        </DefaultParamsContext.Provider>
    );
};

const useDefaultParams = () => useContext(DefaultParamsContext);

export default DefaultParamsProvider;

export {
    useDefaultParams,
};
