import { useEffect } from 'react';

import { useCompanies } from '../../../hooks/use-companies';

const FaviconManager = () => {
    const { state: { company, mobileLogo } } = useCompanies();
    useEffect(() => {
        // Get the existing favicon link element
        const icon = document.querySelector('link[rel*=\'icon\']');

        if (icon && company && mobileLogo) {
            icon.setAttribute('href', mobileLogo);
        }
    }, [ company, mobileLogo ]);

    return null; // FaviconManager doesn't render anything
};

export default FaviconManager;
