import { Company } from '../../common/companies';

enum LinkType {
    TermsOfUse= 1,
    PrivacyPolicy= 2,
    ZZLPSPOIN = 3,
}

const companiesLinks = {
    [Company.Address]: {
        [LinkType.TermsOfUse]: 'https://address.bg/terms-of-use',
        [LinkType.PrivacyPolicy]: 'https://address.bg/privacy-policy',
        [LinkType.ZZLPSPOIN]: 'https://address.bg/podavane-na-signali-po-reda-na-zzlpspoin',
    },
    [Company.Imoteka]: {
        [LinkType.TermsOfUse]: 'https://imoteka.bg/terms-of-use',
        [LinkType.PrivacyPolicy]: 'https://imoteka.bg/privacy-policy',
        [LinkType.ZZLPSPOIN]: 'https://imoteka.bg/pdf/%D0%97%D0%97%D0%9B%D0%9F%D0%A1%D0%9F%D0%9E%D0%98%D0%9D.pdf',
    },
    [Company.UniqueEstates]: {
        [LinkType.TermsOfUse]: 'https://ues.bg/bg/terms',
        [LinkType.PrivacyPolicy]: 'https://ues.bg/bg/conditions',
        [LinkType.ZZLPSPOIN]: 'https://ues.bg/bg/zzlpspoin',
    },
    [Company.NewEstates]: {
        [LinkType.TermsOfUse]: 'https://newestates.bg/uslovia-za-polzvane/',
        [LinkType.PrivacyPolicy]: 'https://newestates.bg/zashtita-na-klienta/',
        [LinkType.ZZLPSPOIN]: 'https://newestates.bg/podavane-na-signali-po-reda-na-zzlpspoin/',
    },
};

export {
    LinkType,
    companiesLinks,
};
