import { IDictionary } from '../common/common-types';
import { Company } from '../common/companies';
import { IHttpCallParams, makeHttpCall } from '../utils/http-utils';

class HttpService {
    company: Company;

    constructor(company: Company) {
        this.company = company;
    }

    // eslint-disable-next-line @typescript-eslint/class-methods-use-this
    private makeHttpCall(obj: IHttpCallParams) {
        const httpCallParams = {
            ...obj,
            headers: {
                ...obj.headers,
                'X-Company': this.company,
            },
        };
        console.log(httpCallParams.headers);
        return makeHttpCall(httpCallParams);
    }

    get(url: string, headers: IDictionary<any> = {}) {
        return this.makeHttpCall({
            url,
            method: 'get',
            headers,
            responseType: 'json',
        });
    }

    post(url: string, data:any = null, headers: IDictionary<any> = {}) {
        return this.makeHttpCall({
            url,
            method: 'post',
            body: data,
            headers,
            responseType: 'json',
        });
    }

    put(url: string, data: any = null, headers: IDictionary<any> = {}) {
        return this.makeHttpCall({
            url,
            method: 'put',
            body: data,
            headers,
            responseType: 'json',
        });
    }
}

export default HttpService;
