import { useMemo } from 'react';

import { useCompanies } from '../../../hooks/use-companies';
import concatClassNames from '../../../utils/class-names';
import CompletePayment from '../../completed-payment/CompletedPayment';
import Banner from '../banner/Banner';
import FaviconManager from '../favicon-manager/FaviconManager';
import FooterMobile from '../footer-mobile/FooterMobile';
import PaymentFlow from '../payment-flow/PaymentFlow';

import styles from './ContentWrapper.module.scss';

interface IContentWrapperProps {
    successfulPayment?: boolean;
}

const ContentWrapper = ({ successfulPayment = false }: IContentWrapperProps) => {
    const { state: { company, logo, mobileLogo } } = useCompanies();

    const wrapperClassName = useMemo(
        () => concatClassNames(styles.wrapper, company),
        [ company ],
    );

    const contentWrapperClassName = useMemo(
        () => concatClassNames(styles.contentWrapper, company),
        [ company ],
    );

    const renderCompletedPayment = () => {
        if (!successfulPayment) {
            return null;
        }
        return <CompletePayment />;
    };

    const renderPaymentFlow = () => {
        if (successfulPayment) {
            return null;
        }

        return (
            <>
                <FaviconManager />
                <Banner className={styles.bannerMobile} imgSrc={logo} mobileImgSrc={mobileLogo} />
                <PaymentFlow />
                <FooterMobile />
            </>
        );
    };
    return (
        <div className={wrapperClassName}>
            <div className={contentWrapperClassName}>
                {renderCompletedPayment()}
                {renderPaymentFlow()}
            </div>
        </div>
    );
};

export default ContentWrapper;
