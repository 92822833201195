import { createContext, useCallback, useContext, useMemo, useState } from 'react';

import { PaymentMethod } from '../common/accounts';
import { IHaveChildrenProps } from '../components/common/Props';
import { IDraftType } from '../contexts/types/types';

import { useDefaultParams } from './use-default-params';

const paymentPlaceholder = 'Избери тип на плащане';

interface IDraftsContext {
    state: {
        draft: IDraftType;
        paymentMethod: PaymentMethod;
    };
    actions: {
        setPaymentAmount: (paymentAmount?: number | null) => void;
        setContractDate: (contractDate?: Date | null) => void;
        setConsultant: (consultant?: string) => void;
        setSignatory: (signatory?: string) => void;
        setPropertyAddress: (propertyAddress?: string) => void;
        setOfferCode: (offerCode?: string) => void;
        setPropertyCity: (propertyCity?: string) => void;
        setPaymentType: (paymentType?: string) => void;

        setName: (name?: string) => void;
        setPersonalIdentificationNumber: (personalIdentificationNumber?: string) => void;
        setPhone: (phone?: string) => void;
        setEmail: (email?: string) => void;
        setCustomerAddress: (customerAddress?: string) => void;
        setIsLegalEntity: (isLegalEntity: boolean) => void;
        setNameOfCompany: (nameOfCompany?: string) => void;
        setFinanciallyLiablePerson: (financiallyLiablePerson?: string) => void;
        setBulstat: (bulstat?: string) => void;
        setAddress: (address?: string) => void;

        setCardName: (cardName?: string) => void;

        setPaymentMethod: (paymentMethod: PaymentMethod) => void;
    };
}

const DraftsContext = createContext<IDraftsContext>({ state: {} } as IDraftsContext);

interface IDraftsProviderProps extends IHaveChildrenProps {
}
const DraftsProvider = ({ children }: IDraftsProviderProps) => {
    const { state: defaultDraft } = useDefaultParams();

    const [ paymentMethod, setPaymentMethod ] = useState(PaymentMethod.card);

    const [ draft, setDraft ] = useState<IDraftType>(defaultDraft);

    const setDraftProp = useCallback(
        (propName: string, value: any) => {
            setDraft({
                ...draft,
                [propName]: value,
            });
        },
        [ draft ],
    );

    const value = useMemo(
        () => ({
            state: {
                draft,
                paymentMethod,
            },
            actions: {
                setPaymentAmount: (amount: any) => setDraftProp('paymentAmount', amount),
                setContractDate: (contractDate: any) => setDraftProp('contractDate', new Date(contractDate)),
                setConsultant: (consultant: any) => setDraftProp('consultant', consultant),
                setSignatory: (signatory: any) => setDraftProp('signatory', signatory),
                setPropertyAddress: (propertyAddress: any) => setDraftProp('propertyAddress', propertyAddress),
                setOfferCode: (offerCode: any) => setDraftProp('offerCode', offerCode),
                setPropertyCity: (propertyCity: any) => setDraftProp('propertyCity', propertyCity),
                setPaymentType: (paymentType: any) => setDraftProp('paymentType', parseInt(paymentType, 10)),

                setName: (name?: any) => setDraftProp('name', name),
                setPersonalIdentificationNumber:
                    (personalIdentificationNumber?: any) => setDraftProp('personalIdentificationNumber', personalIdentificationNumber),
                setPhone: (phone?: any) => setDraftProp('phone', phone),
                setEmail: (email?: any) => setDraftProp('email', email),
                setCustomerAddress: (customerAddress?: any) => setDraftProp('customerAddress', customerAddress),
                setIsLegalEntity: (isLegalEntity: any) => setDraftProp('isLegalEntity', isLegalEntity),
                setNameOfCompany: (nameOfCompany?: any) => setDraftProp('nameOfCompany', nameOfCompany),
                setFinanciallyLiablePerson:
                    (financiallyLiablePerson?: any) => setDraftProp('financiallyLiablePerson', financiallyLiablePerson),
                setBulstat: (bulstat?: any) => setDraftProp('bulstat', bulstat),
                setAddress: (address?: any) => setDraftProp('address', address),

                setCardName: (cardName?: any) => setDraftProp('cardName', cardName),

                setPaymentMethod: (pm: PaymentMethod) => setPaymentMethod(pm),
            },
        }),
        [ draft, paymentMethod, setDraftProp ],
    );

    return (
        <DraftsContext.Provider value={value}>
            {children}
        </DraftsContext.Provider>
    );
};

const useDrafts = () => useContext(DraftsContext);

export default DraftsProvider;

export {
    useDrafts,
    paymentPlaceholder,
};
