import { useMemo } from 'react';

import visaMobileLogo from '../../../assets/logos/mobile/mastercard-mobile-logo.svg';
import stripeMobileLogo from '../../../assets/logos/mobile/stripe-mobile-logo.svg';
import mastercardMobileLogo from '../../../assets/logos/mobile/visa-mobile-logo.svg';
import { useCompanies } from '../../../hooks/use-companies';
import concatClassNames from '../../../utils/class-names';
import { IHaveOptionalClassName } from '../../common/Props';
import PrivacyPolicyButton from '../../footer-links/PrivacyPolictyButton';
import TermsOfUseButton from '../../footer-links/TermsOfUseButton';
import ZzlpspoinButton from '../../footer-links/ZzlpspoinButton';

import styles from './FooterMobile.module.scss';

interface IFooterMobileProps extends IHaveOptionalClassName {
}

const FooterMobile = ({ className = '' }: IFooterMobileProps) => {
    const { state: { company } } = useCompanies();
    const containerClassname = useMemo(() => concatClassNames(className, styles.footerMobileContainer, company), [ company, className ]);

    return (
        <div className={containerClassname}>
            <div className={styles.bannerFooterContainer}>
                <div className={styles.bannerLinksContainer}>
                    <div className={styles.bannerLinks}>
                        <TermsOfUseButton />
                        <PrivacyPolicyButton />
                        <ZzlpspoinButton />
                    </div>
                    <div>
                        <span className={styles.footerRights}>© 1994 - 2024 Адрес, All rights reserved</span>
                    </div>
                </div>
                <div className={styles.paymentLogos}>
                    <img src={stripeMobileLogo} alt="stripe" />
                    <img src={mastercardMobileLogo} alt="mastercard" />
                    <img src={visaMobileLogo} alt="visa" />
                </div>
            </div>
        </div>
    );
};

export default FooterMobile;
